<template>
    <div class="md-layout text-center" @keyup.enter="doLogin">

        <div
            class="md-layout-item md-size-33 md-medium-size-33 md-small-size-70 md-xsmall-size-100 login-container"
        >
            <div class="text-logo">CXVIEW</div>
            <div class="text-slogan">Smart Retail Solution</div>
            <form class="login-form">
                <div class="input-control">
                    <input
                        type="email"
                        :placeholder="$t('login.input_text.email')"
                        name="email"
                        v-model="email"
                        class="width-100 height-100 input-text"
                        ref="emailInput"
                        autofocus="autofocus"
                    />
                </div>
                <div class="input-control password-container">
                    <input
                        readonly
                        type="password"
                        :placeholder="$t('login.input_text.password')"
                        class="width-100 height-100 input-text"
                        name="password"
                        ref="passwordInput"
                        autocomplete="off"
                        v-model="password"
                        style="padding-right: 35px"
                        onfocus="
                            if (this.hasAttribute('readonly')) { 
                                this.removeAttribute('readonly');
                                this.blur();    
                                this.focus();  
                            }
                        "
                    />
                    <span class="password-viewer">
                        <span class="material-icons" v-show="isShowPassword" @click="changeShowPassword($event)">visibility</span>
                        <span class="material-icons" v-show="!isShowPassword" @click="changeShowPassword($event)">visibility_off</span>
                    </span>
                </div>
                <div class="input-control justify-center-wrap" style="height: 24px">
                    <label class="label-text" style="display: flex; align-items: center">
                        <input type="checkbox" :checked="isRememberMe" @change="changeRememberMe($event)"
                               class="input-checkbox"/>
                        {{ $t('login.RememberPassword') }}
                    </label>
                    <div class="wrap">
                        <div style="cursor:pointer; color: #244957;"
                             class="language"
                             @click="chooseLanguageOptionInModal()"
                             v-click-outside="handleClickOutSideLanguageInModal"
                        >
                            {{ languageName }}
                            <span class="material-icons">
                                arrow_drop_down
                            </span>
                            <div
                                ref="wrapLanguageOption"
                                class="WrapOption"
                                v-bind:class="[languageName === 'Tiếng Việt' ? 'left-viet-nam' : 'left-english']"
                            >
                                <div class="Option" style="cursor:pointer" @click="changeLanguage('en', 'English')">
                                    English
                                </div>
                                <div class="Option" style="cursor:pointer" @click="changeLanguage('vn', 'Tiếng Việt')">
                                    Tiếng Việt
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-control">
                    <button class="login-button height-100" @click="doLogin($event)">{{ $t("login.title") }}</button>
                </div>
                <div class="footerText">© CXView All Rights Reserved.</div>
            </form>
        </div>
    </div>
</template>
<script>
import {Api} from "@/api/index";
import utils from "@/utils";
import {LoginCard} from "@/components";
import axios from "axios";
import EventBus from "@/EventBus";

const Login = Api.get("Login");
export default {
    mounted() {
        this.languageName = localStorage.lang == "vn" ? "Tiếng Việt" : "English"
        if (localStorage.lang != "vn") {
            localStorage.setItem("lang", "en")
        }

        this.isRememberMe = localStorage.getItem("isRememberMe") === "true"
        if (this.isRememberMe) {
            this.email = localStorage.getItem("savedEmail")
            this.password = localStorage.getItem("savedPassword")
        } else {
            this.email = ""
            this.password = ""
        }

        // this.$refs.emailInput.$el.focus()
    },
    components: {},
    data() {
        return {
            languageName: "English",
            email: null,
            password: "",
            isShowPassword: false,
            isRememberMe: false
        };
    },
    methods: {
        chooseLanguageOptionInModal() {
            this.$refs["wrapLanguageOption"].style.position = "absolute";
            this.$refs["wrapLanguageOption"].style.display = "block";
            this.$refs["wrapLanguageOption"].style.visibility = "visible";
        },
        handleClickOutSideLanguageInModal() {
            this.$refs["wrapLanguageOption"].style.display = "none";
            this.$refs["wrapLanguageOption"].style.visibility = "hidden";
        },
        changeLanguage(value, valueInVietNamLanguage) {
            var self = this
            setTimeout(() => {
                self.handleClickOutSideLanguageInModal();
            }, 100);
            EventBus.$emit("changeLanguage", value);
            this.languageName = valueInVietNamLanguage
        },
        changeShowPassword(event) {
            event.preventDefault()
            this.isShowPassword = !this.isShowPassword
            if (this.isShowPassword) {
                this.$refs.passwordInput.type = "text"
            } else {
                this.$refs.passwordInput.type = "password"
            }
        },
        changeRememberMe(event) {
            localStorage.setItem("isRememberMe", event.target.checked)
        },
        doLogin(event) {
            event.preventDefault()
            var data = {email: this.email, password: this.password};
            if (this.email == null || this.password == null) {
                this.$swal(this.$t("FaceAttendanceResponse.FS0002"), "", "error");
            } else {
                Login.post(data)
                    .then((response) => {
                        this.response = response.status;
                        if (this.response == 200) {
                            utils.set_token(
                                response.data.token,
                                response.data.product,
                                response.data.role,
                            );
                            axios.defaults.headers.common["Authorization"] =
                                response.data.token;
                            if (localStorage.getItem("email") != this.email) {
                                try {
                                    const channel4Broadcast = new BroadcastChannel('channel4');
                                    channel4Broadcast.postMessage({"group": ""});
                                }
                                catch {
                                    console.log("safary")
                                }
                                
                                localStorage.setItem("group", "")
                                localStorage.setItem("email", this.email)
                            }
                            localStorage.setItem("accountType", response.data.accountType)
                            localStorage.removeItem("currentCustomerData")
                            localStorage.removeItem("currentCustomerRecordData")
                            // channel4Broadcast.postMessage({"token": response.data.token});
                            if (response.data.accountType == "stores") {
                                this.$router.push({name: "Trang chủ"});
                            } else {
                                this.$router.push({name: "Trang chủ shoppingmall"});
                            }

                        }
                    })
                    .then(() => {
                        const isRememberMe = localStorage.getItem("isRememberMe") === "true"
                        if (isRememberMe) {
                            localStorage.setItem("savedEmail", this.email)
                            localStorage.setItem("savedPassword", this.password)
                        } else {
                            localStorage.setItem("savedEmail", "")
                            localStorage.setItem("savedPassword", "")
                        }
                    })
                    .catch((error) => {
                        console.log("error: ", error)
                        this.$swal(this.$t("UserResponse." + error.response.data.message.replace(".", "")), "", "error");
                    });
            }

        },
    },
};
</script>

<style lang="scss" scoped>
.login-container {
    .login-form {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .footerText {
            font-family: "Roboto";
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #143947;
            width: 100%;
        }

        .input-control {
            height: 48px;

            .width-100 {
                width: 100%;
            }

            .height-100 {
                height: 100%;
            }

            margin-bottom: 24px;

            label {
                margin-left: -4px;
            }

            input:focus {
                border: 1px solid #276EFF;
                caret-color: #276EFF;
            }

            input:focus::placeholder {
                color: transparent;
            }

            .login-button {
                cursor: pointer;
                background-color: #276EFF;
                color: #FFFFFF;
                width: 100%;
                display: inline-block;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border: 1px solid transparent;
                padding: 12px 16px 12px 16px;
                border-radius: 8px;
                transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            }

            .login-button:hover {
                background-color: #4F7DFF;
            }

            .login-button:active {
                background-color: #0054FF;
            }
        }

        .password-container {
            position: relative;

            .password-viewer {
                cursor: pointer;
                position: absolute;
                color: #bfbfbf;
                right: 7px;
                top: 13px;
            }
        }

        .justify-center-wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    .input-text {
        padding: 12px 16px;
        background-color: #fff;
        border: 1px solid #515C6B;
        border-radius: 8px;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #8C8C8C;
    }

    .input-checkbox {
        width: 18px;
        height: 18px;
        margin: 0 8px 0 4px;
    }
}

.wrap {
    top: 40px;
    left: 40px;
    z-index: 999999;

    .language {
        height: 24px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        position: relative;
        color: #ffffff;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #224957;

        .material-icons {
            margin-left: 8px;
        }

        .WrapOption {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 8px;
            max-height: 320px;
            position: absolute;
            width: 136px;
            background: #ffffff;
            box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
            0px 3px 4px rgba(10, 31, 68, 0.1);
            border-radius: 10px;
            top: 46px;
            visibility: hidden;

            .Option {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 8px 16px;
                width: 100%;
                height: 36px;
                border-radius: 6px;
                font-family: "Roboto";
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                font-weight: 400;

                &:hover {
                    background-color: #f6f9ff;
                }
            }
        }
    }
}

.text-logo {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #224957;
    margin-bottom: 16px;
}

.text-slogan {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #224957;
    margin-bottom: 40px;
}

.label-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #093545;
}


.left-english {
    left: -43px;
}

.left-viet-nam {
    left: -24px;
}

</style>
